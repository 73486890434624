@media(max-width: 1200px) {
  #logo {
    width: 100% !important;
    text-align: center;
  }
  .header-with-links {
    width: 100% !important;
  }
  #block-views-sponsors-block-platinum ul {
    text-align: center;
  }
  #block-views-sponsors-block-gold ul {
    text-align: center;
  }
}
@media(max-width: 992px) {
  #superfish-1 {
    display: none;
  }
  .region-navigation {
    .sf-horizontal {
      display: none;
    }
    .block-superfish {
      .sf-accordion-toggle {
        text-align: center;
        &:hover {
          background-color: #333333;
        }
      }
      #superfish-1-toggle {
        display: block;
        padding: 15px 20px;
        font-size: 18px;
        font-weight: normal;
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
  #main-navigation {
    ul.menu {
      ul {
        display: block;
      }
      li a {
        display: block;
      }
    }
    .sf-accordion .menuparent {
      > a {
        padding: 15px 40px 15px 20px !important;
      }
      > ul {
        background-color: $red;
        color: $white;
        //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
        box-shadow: none;
        border: none;
        > li > a {
          border: none;
        }
      }
    }
    #block-superfish-1 #superfish-1-accordion {
      overflow: hidden;

      li {
        display: block;
        width: 100%;

        ul {
          padding-left: 25px;
        }

        &.sf-expanded {
          ul {
            position: relative;
            width: 100%;
            left: 0;

            li {
              width: 100%;

              &.sf-clone-parent {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .sf-accordion .menuparent > a:after {
    top: 15px !important;
  }
}
@media(max-width: 767px) {
  #toTop {
    display: none !important;
  }
  #promoted {
    display: none !important;
  }
  .tabs {
    &.primary {
      height: 20px;
      max-width: 100% !important;
      padding-left: 0;
    }
    ul.primary li {
      float: left;
      margin-bottom: 10px;
    }
  }
  #logo {
    width: 100%;
    text-align: center;
  }
  #block-search-form {
    display: none;
  }
  #header ul.menu {
    text-align: center;
  }
}
@media(max-width: 480px) {
  #doormat {
    .block {
      width: 100%;
      text-align: center;
    }
    #block-rocketlabs-webdesign-by-rocketlabs {
      width: 100%;
      text-align: center;
    }
  }
  th.mobile-hidden {
    display: none;
  }
  td.mobile-hidden {
    display: none;
  }
}
