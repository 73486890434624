#header {
  padding: 0;
  border: none;
  background-color: transparent;
  .container {
    background-color: $grey;
  }
  ul {
    text-align: right;
    &.menu {
      padding-bottom: 10px;
      text-align: right;
      li {
        padding: 3px 10px;
        a {
          padding: 0;
        }
      }
    }
  }
}
#header-inside {
  padding: 0;
  background-color: $grey;
}
#logo {
  float: left;
  margin: 0;
  padding: 25px 0;
  text-align: left;
}
#site-slogan {
  float: left;
  margin: 0;
  padding: 25px 0;
  font-size: 60px;
  line-height: 100px;
  color: inherit;
}
.header-with-links {
  float: right;
  padding: 25px 0;
  .item-list ul {
    margin: 0;
    overflow: hidden;
    text-align: right;
  }
}
.region-header .block {
  padding: 0;
}
