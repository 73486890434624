.view-sponsors {
  .view-footer {
    clear: both;
  }
  .view-content .item-list ul {
    display: block;
    li {
      display: inline-block;
      margin: 0;
      padding: 15px 5px;
      border-bottom: 0;
      list-style: none;
      list-style-type: 0;
    }
  }
}
.views-field-field-picture-sponsor {
  .sponsorship-Platinum,
  .sponsorship-Diamond {
    width: 100%;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
  }
  .sponsorship-Gold {
    width: 50%;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    float: left;
  }
  .sponsorship-Silver,
  .sponsorship-Bronze,
  .sponsorship-Support,
  .sponsorship-Produkt {
    width: 25%;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    float: left;
  }

  .sponsorship-Platinum,
  .sponsorship-Diamond,
  .sponsorship-Gold,
  .sponsorship-Silver,
  .sponsorship-Bronze,
  .sponsorship-Support,
  .sponsorship-Produkt {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
#block-views-sponsors-block-platinum {
  clear: none;
}
#block-views-sponsors-block-gold {
  clear: none;
}
.sponsorship-group {
  clear: both;
  overflow: hidden;
}
