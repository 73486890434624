.sidebar {
  h2 {
    margin-top: 0;
    margin-bottom: 10px;
    background: url(../img/bgtitle.png) repeat-x;
    background-position: center;
    line-height: 20px;
    text-transform: uppercase;
    &:after {
      display: none;
    }
    > span {
      padding-right: 10px;
      background-color: $sidebar-grey-light;
    }
  }
  ul li {
    margin: 0;
    list-style: none;
    list-style-type: none;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
.sidebar-block-white {
  padding: 20px 25px;
  border: 1px solid $sidebar-grey-dark;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 6px;
  background-color: $sidebar-grey-light;
}
