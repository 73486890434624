#main {
  padding-bottom: 0;
}
#block-system-main {
  padding-bottom: 0;
}
#main-content {
  padding: 0;
  .container {
    padding-top: 20px;
    background-color: $grey;
  }
}
.node {
  .content {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    h3 {
      text-transform: uppercase;
    }
    table {
      thead {
        background-color: $red;
        color: $white;
        th {
          vertical-align: middle;
        }
      }
      tbody {
        td {
          vertical-align: middle;
        }
        tr:nth-child(even) {
          background-color: $grey;
        }
      }
      .table-95 {
        width: 95%;
      }
      .table-90 {
        width: 90%;
      }
      .table-85 {
        width: 85%;
      }
      .table-80 {
        width: 80%;
      }
      .table-75 {
        width: 75%;
      }
      .table-70 {
        width: 70%;
      }
      .table-65 {
        width: 65%;
      }
      .table-60 {
        width: 60%;
      }
      .table-55 {
        width: 55%;
      }
      .table-50 {
        width: 50%;
      }
      .table-45 {
        width: 45%;
      }
      .table-40 {
        width: 40%;
      }
      .table-35 {
        width: 35%;
      }
      .table-30 {
        width: 30%;
      }
      .table-25 {
        width: 25%;
      }
      .table-20 {
        width: 20%;
      }
      .table-15 {
        width: 15%;
      }
      .table-10 {
        width: 10%;
      }
      .table-5 {
        width: 5%;
      }
    }
    .field-items p {
      padding-bottom: 10px;
    }
  }
  &.node-teaser {
    margin-bottom: 0;
    padding-bottom: 20px;
    border: none;
    border-bottom: 1px dashed$grey-dark;
    background-color: transparent;
    &:last-child {
      border-bottom: none;
    }
    h1 {
      margin: 0;
      padding: 20px 0 0;
      font-size: 32px;
    }
    .content {
      padding: 20px 0;
      .field-name-field-picture-activity {
        padding-bottom: 20px;
      }
      .field-items p {
        padding-bottom: 10px;
      }
    }
    .field-name-node-link {
      margin: 15px 0 0;
    }
  }
}
.table-calendar {
  thead {
    background-color: $red;
    color: $white;
  }
  tbody tr:nth-child(even) {
    background-color: $grey;
  }
}
#content-wrapper,
.page-contact #content-wrapper {
  margin-bottom: 30px;
  padding: 0 20px 20px;
  border: 1px solid $sidebar-grey-dark;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 6px;
  background-color: $sidebar-grey-light;
}
.view-voorpagina {
  .views-row {
    padding-bottom: 20px;
    border-bottom: 1px dashed $grey-dark;
  }
  .views-row-last {
    border-bottom: none;
  }
}

h1.page-title {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-node #content-wrapper h1.page-title {
  padding-left: 0;
  padding-right: 0;
}
.front #block-system-main {
  .node-teaser:last-child {
    border-bottom: none;
  }
  ul.pager {
    margin-top: 15px;
    margin-bottom: 0;
    li {
      a {
        margin-bottom: 0;
        padding: 8px 20px;
      }
      &.pager-current {
        margin-bottom: 0;
        padding: 8px 20px;
      }
    }
  }
}

#promoted {
  margin-bottom: 20px;
  padding: 20px 25px;
  border: 1px solid $sidebar-grey-dark;
  border-radius: 6px;
  background-color: $sidebar-grey-light;
  #block-views-slideshow-block-slideshow {
    padding-bottom: 0;

    .skin-default {
      position: relative;
    }

    .views-slideshow-controls-top {
      position: absolute;
      top: 50%;
      left: 20px;
      right: 20px;
      margin-top: -15px;
      z-index: 10;
    }

    .views-slideshow-cycle-main-frame-row-item .views-field-body {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      padding: 10px;
      background-color: rgba(69,60,56,0.5);
      color: $white;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 1px 1px rgba(69,60,56,0.75);

      h1 {
        margin: 0;
        font-size: 18px;
      }

    }

    .views-field-field-picture-slide img {
      display: block;
    }

  }
}

#views_slideshow_controls_text_slideshow-block_slideshow {
  .views-slideshow-controls-text-previous {
    float: left;
    display: inline-block;
    height: 30px;
    width: 21px;
    background: url(../img/slider/previous.png) no-repeat;
    text-indent: -9999px;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s;
    &:hover {
      margin-left: -10px;
    }
  }
  .views-slideshow-controls-text-pause {
    display: none;
  }
  .views-slideshow-controls-text-next {
    float: right;
    display: inline-block;
    height: 30px;
    width: 21px;
    background: url(../img/slider/next.png) no-repeat;
    text-indent: -9999px;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s;
    &:hover {
      margin-right: -10px;
    }
  }
}
.simplenews-subscribe {
  margin-top: 10px;
}
.simplenews-unsubscribe {
  margin-top: 10px;
}

#block-search-form {
  position: relative;
  min-width: 200px;
  .form-actions {
    right: 10px;
  }
}
.field-name-field-picture-activity {
  padding-bottom: 20px;
}

.page-contact #content-wrapper {
  padding-bottom: 0;
}
.view-voorpagina .view-empty {
  padding-top: 20px;
}

.page-contact .contact-form .form-actions {
  margin-bottom: 0;
}
