.webform-component {
  clear: left;
  .webform-datepicker {
    .form-type-select {
      width: 30%;
      float: left;
      padding-right: 10px;
      margin: 0 0 1em 0;
    }
    .webform-calendar {
      margin: 0 0 1em 0;
    }
  }
}
.fieldset-legend .fieldset-title {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #444;
}

input {
  &.form-text {
    padding: 7px 14px;
    min-height: 36px;
  }
  &.form-submit {
    max-width: 100%;
    padding: 7px 24px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
select {
  padding: 7px 14px;
  min-height: 36px;
}
#search-block-form input.form-text {
  padding: 14px;
  min-height: 50px;
}
#masquerade-block-1 input.form-text {
  padding: 0 10px;
  height: 12px;
  min-height: 20px;
  font-size: 12px;
  line-height: 12px;
}
.sidebar .block {
  margin-bottom: 20px;
}
textarea.form-textarea {
  max-width: 100%;
}
