body {
  background: url(../img/bg.png) repeat;
  font-size: 14px;
  font-family: droid_sansregular, sans-serif;
  color: $black;
}
p {
  margin: 0;
}
img.file-icon {
  display: inline-block;
}

#toTop {
  background-color: $black;
}
.contact-form {
  padding-bottom: 10px;
}
.node-addressinformation {
  > h1 {
    display: none;
  }
  .content {
    padding-top: 0;
  }
}
#comments {
  padding: 0;
}
