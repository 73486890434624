.view-display-id-block_activity_info .views-field {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed $grey-dark;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 10px;
    border-bottom: none;
  }
  .item-list ul {
    margin-bottom: 0;
    li {
      padding-bottom: 0;
      padding-top: 0;
      border-bottom: none;
    }
  }
}
.view-display-id-block_activity_signup .views-field p {
  padding-bottom: 10px;
}
.node-type-activity .field-name-body .field-item p {
  margin-bottom: 15px;
}
#block-views-kalender-block-activities .view-display-id-block_activities .item-list ul {
  margin: 0;
}
.view-kalender ul.pager {
  height: 48px;
  margin-top: -39px;
  margin-bottom: 0;
  &:before {
    display: none;
  }
}
ul.pager:after {
  display: none;
}
.view .date-nav-wrapper {
  .date-prev {
    top: 0;
    left: 0;
    position: absolute;
    height: 48px;
    width: 150px;
    background: transparent;
    a {
      display: block;
      position: absolute;
      top: 5px;
      margin: 0;
      padding: 7px 20px;
      font-weight: normal;
      &:hover {
        color: $white;
      }
    }
  }
  .date-next {
    top: 0;
    right: 0;
    position: absolute;
    height: 48px;
    width: 150px;
    background: transparent;
    a {
      display: block;
      position: absolute;
      top: 5px;
      margin: 0;
      padding: 7px 20px;
      font-weight: normal;
      right: 0;
      &:hover {
        color: $white;
      }
    }
  }
}
