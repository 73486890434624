/**
 * Colors
 */

$white: white;
$grey: #f1f1f1;
$grey-dark: #dddddd;
$red: #df1d25;
$red-light: #e74c3c;
$black: #333333;
$dark-black: #000000;

$sidebar-grey-light: #fcfcfc;
$sidebar-grey-dark: #eaeaea;
