/* -------------------------------------------------------------------------- */
/* Cookie verklaring */
/* -------------------------------------------------------------------------- */


.decency-cookie-compliance-status {
  @media only screen and (min-width: 960px) {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .container {
    margin: 0 !important;
    padding: 0 !important;
    width: auto;
    background-color: inherit !important;
  }

  #decency-cookie-status-wrapper {
    font-weight: bold;
  }
}

@media only screen and (min-width: 1292px) {
  .decency-cookie-compliance .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .decency-cookie-compliance .decency-cookie-compliance__text {
    -ms-flex-preferred-size: 70%;
    flex-basis: 70%;
  }

  .decency-cookie-compliance .decency-cookie-compliance__button-wrapper {
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
  }
}

.decency-cookie-compliance {
  border-top: 2px solid $black;
  background: $red;
  color: $white;
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .container {
    background-color: inherit !important;
    position: relative;
    margin: auto;
    padding-top: 0 !important;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1200px;
  }

  a {
    color: $white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .button {
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;

    &.button-primary {
      transition: all 0.3s ease-in-out;
      background-color: $black;
      color: white;

      &:hover {
        background-color: $dark-black;
        color: white;
        text-decoration: none;
      }
    }
  }
}

.decency-cookie-compliance__button-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin: 16px 0 0;
}

@media only screen and (min-width: 1292px) {
  .decency-cookie-compliance__button-wrapper {
    margin:0 0 0 16px;
  }
}

.decency-cookie-compliance__button-wrapper>div {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.decency-cookie-compliance__button-wrapper>div:first-child {
  margin: 0 0 16px;
}

@media only screen and (min-width: 542px) {
  .decency-cookie-compliance__button-wrapper>div:first-child {
    margin:0 16px 0 0;
  }
}

@media only screen and (min-width: 542px) {
  .decency-cookie-compliance__button-wrapper>div {
    -ms-flex-preferred-size:auto;
    flex-basis: auto;
  }
}

.decency-cookie-compliance__button-wrapper a {
  margin: 0;
}
