.view-display-id-page_teams_overview {
  .views-row-even {
    display: inline-block;
    max-width: 30%;
    margin: 0 1.4% 20px 1.4%;
    font-weight: bold;
    text-align: center;
  }
  .views-field-title a {
    color: $black;
    &:hover {
      text-decoration: none;
      color: $red-light;
    }
    img {
      display: block;
    }
    span {
      display: block;
      margin-top: 10px;
    }
  }
}
.view-display-id-page_teams_overview_category {
  .views-row-odd {
    display: inline-block;
    max-width: 30%;
    margin: 0 1.4% 20px 1.4%;
    font-weight: bold;
    text-align: center;
    a {
      color: $black;
      &:hover {
        text-decoration: none;
        color: $red-light;
      }
      img {
        display: block;
      }
      span {
        display: block;
        margin-top: 10px;
      }
    }
  }
  .views-row-even {
    display: inline-block;
    max-width: 30%;
    margin: 0 1.4% 20px 1.4%;
    font-weight: bold;
    text-align: center;
    a {
      color: $black;
      &:hover {
        text-decoration: none;
        color: $red-light;
      }
      img {
        display: block;
      }
      span {
        display: block;
        margin-top: 10px;
      }
    }
  }
}
.node-team {
  .field-name-field-picture-team .field-items img {
    margin-bottom: 20px;
  }
  .field-name-body table {
    margin-bottom: 0;
  }
}
.table-ranking {
  margin-bottom: 0;
  border: none;
  border-bottom: none;
  .center {
    text-align: center;
  }
  .active {
    color: $red;
  }
  > thead > tr > th {
    border: none;
    border-bottom: none;
  }
}

.view-display-id-page_teams_overview .views-row-odd {
  display: inline-block;
  max-width: 30%;
  margin: 0 1.4% 20px 1.4%;
  font-weight: bold;
  text-align: center;
}
.view-display-id-block_team_info .views-field {
  .views-label {
    font-weight: bold;
  }
  &:not(:first-child) {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px dashed $grey-dark;
  }
  .node.node-teaser {
    padding-bottom: 0;
  }
}
.view-display-id-block_upcomming_games {
  h3 {
    margin: 0 0 5px 0;
    font-size: 14px;
    font-weight: bold;
  }
  .date-display-single {
    font-weight: bold;
  }
  .item-list {
    margin-bottom: 0.75em;
    padding-bottom: 0.75em;
    border-bottom: 1px dashed $grey-dark;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
    ul {
      margin-bottom: 0;
      li {
        margin: 0;
        padding: 0;
        border: none;
      }
    }
  }
}
