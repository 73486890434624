/**
 * Typography
 */

@font-face {
  font-family: 'droid_sansregular';
  src: url('../fonts/DroidSans-webfont.eot');
  src: url('../fonts/DroidSans-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DroidSans-webfont.woff') format('woff'),
  url('../fonts/DroidSans-webfont.ttf') format('truetype'),
  url('../fonts/DroidSans-webfont.svg#droid_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'droid_sansbold';
  src: url('../fonts/DroidSans-Bold-webfont.eot');
  src: url('../fonts/DroidSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DroidSans-Bold-webfont.woff') format('woff'),
  url('../fonts/DroidSans-Bold-webfont.ttf') format('truetype'),
  url('../fonts/DroidSans-Bold-webfont.svg#droid_sansbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
