#main-navigation {
  background-color: transparent;
  box-shadow: none;
  .container {
    background-color: $red;
    color: $white;
  }
  .sf-horizontal {
    ul {
      width: auto;
      margin-left: -1px;
      background-color: $red;
      color: $white;
      white-space: nowrap;
      box-shadow: none;
    }
    > .menuparent {
      > a {
        padding: 15px 40px 15px 20px !important;
      }
      > ul > li {
        > ul {
          margin-left: -1px;
          > li {
            border-left: 1px solid $white;
          }
        }
        > a {
          padding: 10px 40px 10px 20px !important;
          &:after {
            top: 10px !important;
          }
        }
      }
    }
  }
  ul.sf-horizontal ul li a {
    padding: 10px 20px;
    text-transform: none;
  }
}
#main-navigation-inside ul.menu li {
  float: left;
  a {
    padding: 15px 20px;
    font-size: 18px;
    font-weight: normal;
    color: $white;
  }
  &:hover {
    background-color: $black;
  }
}
.sf-horizontal .menuparent > a:after {
  top: 15px !important;
}
.block-superfish ul.sf-horizontal > li > ul {
  &:before {
    display: none;
  }
  &:after {
    display: none;
    display: none;
    border-color: transparent transparent transparent transparent;
  }
}

.sf-hidden {
  display: none !important;
}

.sf-expanded {
  display: block;
}
