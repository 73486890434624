#footer {
  margin: 0;
  padding: 0;
  border-top: 3px solid $black;
  border-bottom: none;
  background-color: $red;
  color: $white;
  h2 {
    margin-bottom: 10px;
    text-transform: uppercase;
    &:after {
      display: none;
    }
  }
  .headsponsors {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      padding: 10px;
      list-style: none;
    }
  }
}
#subfooter {
  margin: 0;
  padding: 0;
  border-top: 3px solid $black;
  border-bottom: none;
  background-color: $red;
  color: $white;
  .block {
    padding: 0;
  }
  .view-sponsors .item-list ul {
    margin: 6px 0 0;
    text-align: center;
  }
}
.liner-footer {
  height: 2px;
  background: url(../img/liner-footer.png) repeat-x;
}
#doormat {
  margin: 0;
  padding: 0;
  border-top: none;
  border-bottom: none;
  background-color: $red;
  color: $white;
  .block {
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    padding: 10px 0;
    width: 50%;
  }
  .region {
    overflow: hidden;
  }
  #block-block-1 {
    float: left;
  }
  #block-rocketlabs-webdesign-by-rocketlabs {
    float: right;
    clear: none;
    text-align: right;
  }
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
