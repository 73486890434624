.page-node-189 {
  #content-wrapper {
    background-color: $black;
    color: $white;
  }
  .node .content table tbody tr:nth-child(even) {
    background-color: $black;
  }
}

.node-type-article {
  table {
    &.collection {
      border: 0;
      background-color: $dark-black;
    }
  }
}
