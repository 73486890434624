$phablet: 650px;
$tablet: 1024px;
$laptop: 1280px;
$desktop: 1500px;

.maintenance-page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display:flex;
  justify-content:center;
  align-items:center;

  .layout-container {

    margin: 1.5em;



    @media (min-width: #{$phablet}) {
      max-width: 600px;
    }

    #maintenance-footer {
      padding: 25px 0 0;

      p,
      .divider {
        margin: 5px 0;
        display: inline-block;
      }

      .divider {
        margin: 0 10px;
      }
    }
  }
}
