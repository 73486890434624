.btn-facebook {
  margin: 5px;
  background-color: #4c66a4;
  color: $white;
  &:hover {
    background-color: #306199;
    color: $white;
    text-decoration: none;
  }
}
.btn-mail {
  margin: 5px;
  background-color: #097ce7;
  color: $white;
  &:hover {
    background-color: #097ce7;
    color: $white;
    text-decoration: none;
  }
}
.btn-location {
  max-width: 100%;
  margin: 5px;
  background-color: #444;
  color: $white;
  &:hover {
    background-color: #333333;
    color: $white;
  }
}
.btn-normal {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.3s ease-in-out;
  margin: 15px 5px 5px;
  background-color: #444;
  color: $white;
  text-transform: uppercase;
  &:hover {
    background-color: #e74c3c;
    color: $white;
  }
}
